<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">

      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->

      <b-navbar-nav class="nav">
        <b-nav-item
          id="calendarico"
          :to="userData.role === 'admin' ? { name: 'apps-calendar' } : userData.role === 'agent' ? { name: 'shift-calendar' } : { name: 'clientcalendar' }"
        >
          <feather-icon
            icon="CalendarIcon"
            size="21"
          />
          <b-tooltip
            triggers="hover"
            target="calendarico"
            :title="userData.role === 'client' ? 'Calendar' : 'Shift Calendar'"
            :delay="{ show: 1000, hide: 50 }"
          />
        </b-nav-item>

        <!-- <b-nav-item-dropdown
          v-if="userData.role === 'admin'"
          id="admin-broadcast"
          variant="link"
          toggle-class="nav-link-custom"
          left
        >
          <template #button-content>
            <feather-icon
              icon="RadioIcon"
              size="21"
            />
          </template>
          <b-dropdown-item
            :to="{ name: 'broadcast' }"
          >
            Broadcast
          </b-dropdown-item>
          <b-dropdown-item
            :to="{ name: 'broadcast-log' }"
          >
            Log
          </b-dropdown-item>
        </b-nav-item-dropdown> -->

        <!-- <b-nav-item
          v-if="userData.role === 'agent' || userData.role === 'client' ? false:true"
          id="admin-worksafe"
          :to="{ name: 'apps-worksafe' }"
        >
          <feather-icon
            icon="FileTextIcon"
            size="21"
          />
          <b-tooltip
            triggers="hover"
            target="admin-worksafe"
            title="Work Safe"
            :delay="{ show: 1000, hide: 50 }"
          />
        </b-nav-item> -->
        <b-nav-item
          v-if="userData.role === 'client' ? true:false"
          id="client-carelogs"
          :to="{ name: 'client-client-carelogs' }"
        >
          <!-- <b-img
            :src="require(`@/assets/images/icons/custom-icons/carelogs.svg`) || ''"
            fluid
            alt="img"
            class="mr-1 c-icons"
          /> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.207"
            height="18.627"
            viewBox="0 0 16.207 18.627"
          >
            <g
              id="care-logs"
              transform="translate(-84.566 -63.85)"
            >
              <path
                id="Path_830"
                data-name="Path 830"
                d="M107.432,67.6q0-3.816,0-7.633a1.447,1.447,0,0,1,1.553-1.543H121.1A1.457,1.457,0,0,1,122.653,60c0,1.23.005,2.46,0,3.69a.444.444,0,0,0,.259.471.856.856,0,0,1,.482.85q-.008,5.14,0,10.28a1.444,1.444,0,0,1-1.526,1.514h-12.9a1.446,1.446,0,0,1-1.528-1.54Q107.432,71.435,107.432,67.6Zm7.951,7.712h6.111c.4,0,.4,0,.4-.411-.011-1.4-.034-2.8-.033-4.194,0-1.7.02-3.4.035-5.095a.446.446,0,0,0-.229-.464c-.7-.395-1.388-.822-2.094-1.211a.943.943,0,0,1-.534-.91c.005-.9,0-1.8,0-2.7,0-.409,0-.41-.422-.41h-9.293c-.4,0-.4,0-.4.409.011,2.28.031,4.56.033,6.841,0,1.417-.026,2.833-.033,4.25-.006,1.173,0,2.347,0,3.52,0,.373.006.379.377.379ZM121.1,63.03c.009-.095.021-.167.021-.24,0-.863-.005-1.726.005-2.59,0-.208-.067-.283-.271-.285s-.3.065-.295.293c.013.713,0,1.426,0,2.139C120.569,62.824,120.589,62.851,121.1,63.03Z"
                transform="translate(-22.741 5.546)"
                fill="currentColor"
                stroke="currentColor"
                stroke-width="0.25"
              />
              <path
                id="Path_831"
                data-name="Path 831"
                d="M189.853,159.4c.288,0,.577-.008.865,0a.554.554,0,0,1,.617.625c0,.335,0,.67,0,1,0,.36.01.368.383.37.335,0,.67,0,1,0a.536.536,0,0,1,.608.575c.019.6.017,1.209,0,1.814-.011.4-.221.585-.623.591-.335,0-.67,0-1,0-.359,0-.368.01-.369.384,0,.326,0,.651,0,.977,0,.418-.19.627-.605.637-.6.014-1.191.016-1.786,0-.4-.011-.584-.219-.589-.624,0-.344-.013-.689,0-1.032.011-.249-.07-.358-.333-.345-.343.017-.688.007-1.032,0-.419-.005-.627-.19-.636-.606-.014-.586-.012-1.172,0-1.758a.551.551,0,0,1,.626-.616c.344,0,.689-.013,1.032,0,.249.011.358-.07.345-.333-.017-.343-.007-.688,0-1.032a.562.562,0,0,1,.633-.638C189.277,159.4,189.565,159.4,189.853,159.4Zm-.424,1.871c0,.177,0,.353,0,.53a.617.617,0,0,1-.679.675c-.26.006-.521,0-.781,0-.576,0-.571,0-.543.589.008.174.064.251.247.246.362-.01.725-.008,1.088,0a.608.608,0,0,1,.667.657c.008.269,0,.539,0,.809.012.555-.133.532.566.537.209,0,.276-.079.268-.282-.013-.353-.009-.707,0-1.06a.609.609,0,0,1,.663-.661c.26-.006.521,0,.781,0,.6,0,.593,0,.559-.6-.01-.187-.09-.236-.261-.232q-.53.012-1.06,0a.618.618,0,0,1-.683-.7c0-.335-.011-.67,0-1,.009-.211-.053-.3-.278-.3-.685.008-.542-.018-.557.548C189.428,161.107,189.43,161.191,189.429,161.275Z"
                transform="translate(-97.108 -89.676)"
                fill="currentColor"
              />
            </g>
          </svg>

          <b-tooltip
            triggers="hover"
            target="client-carelogs"
            title="Carelogs"
            :delay="{ show: 1000, hide: 50 }"
          />
        </b-nav-item>
      </b-navbar-nav>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--<locale />-->
      <!--<dark-Toggler class="d-none d-lg-block" />-->
      <!-- <search-bar /> -->
      <!--<cart-dropdown />-->
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BLink, BNavbarNav, BTooltip, BNavItem, BNavItemDropdown, BDropdownItem, BImg,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import store from '@/store'
import Bookmarks from './components/Bookmarks.vue' // uncomment this after
// import Locale from './components/Locale.vue'
// import SearchBar from './components/SearchBar.vue' // uncomment this after
// import DarkToggler from './components/DarkToggler.vue'
// import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    BTooltip,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BImg,

    // Navbar Components
    BNavbarNav,
    // eslint-disable-next-line vue/no-unused-components
    Bookmarks,
    // Locale,
    // SearchBar, // uncomment this after
    // DarkToggler,
    // CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const userData = getUserData()
    // if (userData.role === 'agent') store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'sticky', backgroundColor: 'secondary' })
    // if (userData.role === 'agent' || userData.role === 'client') store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'sticky', backgroundColor: 'secondary' })
    // else
    store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'floating', backgroundColor: '' })
    return {
      userData,
    }
  },
}
</script>
